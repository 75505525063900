import { render, staticRenderFns } from "./addProgram.vue?vue&type=template&id=db42dde6&"
import script from "./addProgram.vue?vue&type=script&lang=js&"
export * from "./addProgram.vue?vue&type=script&lang=js&"
import style0 from "./addProgram.vue?vue&type=style&index=0&id=db42dde6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports